import { useMeQuery } from "../generated/graphql";

const useAuth = () => {
  const { data } = useMeQuery();

  const auth = data?.me;

  return { auth };
};

export default useAuth;
