import React from "react";
import Helmet from "react-helmet";

type MetaInfo = {
  title: string;
  description: string;
  keywords: string[];
  canonical: string;
  image: string;
  imageWidth: number;
  imageHeight: number;
};

interface Props {
  info: MetaInfo;
}

const Head: React.FC<Props> = ({ info }) => {
  const {
    title,
    description,
    keywords,
    canonical,
    image,
    imageWidth,
    imageHeight,
  } = info;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(",")} />

      <link rel="canonical" href={canonical} />

      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:url" content={canonical} />
      <meta name="og:type" content="website" />
      <meta name="og:image" content={image} />
      <meta name="og:image:width" content={imageWidth.toString()} />
      <meta name="og:image:height" content={imageHeight.toString()} />
    </Helmet>
  );
};

export default Head;
