import React from "react";
import {
  ThemeWrapper,
  LayoutContainer,
  Logo,
  P,
  H2,
} from "time-tracker-app-lib";
import { Box, Flex } from "ticeboxes";
import { Row, Col } from "reactstrap";

import Footer from "../components/Footer";
import WistiaTeaser from "../components/WistiaTeaser";
import MobilePreview from "../components/MobilePreview";
import Head from "../components/Head";
import DownloadApp from "../components/DownloadApp";
import GetStartedButton from "../components/GetStartedButton";

interface Props {}

const meta = {
  title: "Time Tracker App - Simple Time Tracker for Managing Projects",
  description:
    "Time Tracker App is the best free time tracker app to manage your projects. Time tracker App combines the simplicity of your average timer app, while still providing valuable insights for your project time management needs.",
  keywords: [
    "time tracker app",
    "timer app",
    "project manager",
    "project tracker",
    "best time tracker app",
    "free timer app",
    "timer",
    "pomodoro timer",
  ],
  canonical: "https://time-tracker-app.com/",
  image: "https://time-tracker-app.com/TTAThumbnail-lg.jpg",
  imageWidth: 1200,
  imageHeight: 630,
};

const index: React.FC<Props> = () => {
  return (
    <ThemeWrapper>
      <Head info={meta} />

      <header>
        <Box className="layout-mt-5 layout-mt-md-6 layout-mb-5 layout-mb-md-7">
          <Logo />
        </Box>
      </header>

      <main>
        <section>
          <LayoutContainer size="wide" className="layout-mb-7 layout-mb-md-8">
            <Row>
              <Col
                className="order-md-2 layout-mb-6 layout-mb-md-1"
                xs="12"
                md="6"
                xl={{ size: 6, offset: 1 }}
              >
                <Box>
                  <WistiaTeaser />
                </Box>
              </Col>

              <Col className="order-md-1" xs="12" md="6" xl="5">
                <Box text-center text-md-left h-100>
                  <Flex align-items-center h-100>
                    <Box>
                      <H2 className="layout-mb-4">
                        Time tracking made simple.
                      </H2>
                      <P className="layout-mb-4">
                        Time tracker App combines the simplicity of your average
                        timer app, while still providing valuable insights for
                        your project time management needs. Coming soon.
                      </P>
                      <GetStartedButton />
                    </Box>
                  </Flex>
                </Box>
              </Col>
            </Row>
          </LayoutContainer>
        </section>

        <section>
          <LayoutContainer className="layout-mb-7 layout-mb-md-8">
            <H2 className="text-center layout-mb-6">Track time anywhere.</H2>
            <MobilePreview />
          </LayoutContainer>
        </section>

        <section>
          <LayoutContainer className="layout-mb-7 layout-mb-md-8">
            <DownloadApp />
          </LayoutContainer>
        </section>

        <section>
          <LayoutContainer size="medium">
            <Box text-center text-md-left>
              <H2 className="layout-mb-5">Why another time tracker app?</H2>

              <P className="layout-mb-5" cursive>
                As a self-employed freelancer, I wanted a tool that is simple
                enough for just tracking time for the projects I worked on.
              </P>

              <P className="layout-mb-5">
                I made my invoices and contracts myself and just wanted an
                application that could track my time for the various projects I
                was working on. No more than that! And even though I thought
                this was an app that must be out there somewhere, it was hard to
                find one that was simple enough for my time management needs.
              </P>

              <P cursive className="layout-mb-5">
                Time tracker app is already working great for me and I use it
                every day for my own freelance projects. I already saved lots of
                time with it and I love it’s simplicity.
              </P>

              <P className="layout-mb-5">
                Now I want to help other people get a hold on the time they
                spend on their projects too! I often saw these large SaaS
                products that have a time tracker tool, but oftentimes offer
                this as part of a larger package including invoicing,
                contracting etc. Oftentimes it was quite hard to just use it for
                time tracking alone and was quite costly, because of these extra
                features.
              </P>

              <P cursive className="layout-mb-5">
                That’s why I want to offer this app for 1 coffee a month!
              </P>

              <P className="layout-mb-5">
                Yes it’s going to cost only $5 a month for unlimited access. You
                can already start tracking time for free including 3 projects. I
                think that’s quite a nice deal!
              </P>
            </Box>
          </LayoutContainer>
        </section>
      </main>

      <Footer />
    </ThemeWrapper>
  );
};

export default index;
