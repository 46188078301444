import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};



export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type LeadSignupInput = {
  email: Scalars['String'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  register: UserResponse;
  login: UserResponse;
  logout: SuccessResponse;
  completeOnBoarding: SuccessResponse;
  confirmAccount: SuccessResponse;
  resendActivationEmail: SuccessResponse;
  forgotPassword: SuccessResponse;
  resetPassword: SuccessResponse;
  createTimeEntry: TimeEntryResponse;
  updateTimeEntry: TimeEntryResponse;
  removeTimeEntry: TimeEntryResponse;
  leadSignup: SuccessResponse;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationConfirmAccountArgs = {
  token: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
  token: Scalars['String'];
};


export type MutationCreateTimeEntryArgs = {
  input: TimeEntryInput;
};


export type MutationUpdateTimeEntryArgs = {
  input: UpdateTimeEntryInput;
};


export type MutationRemoveTimeEntryArgs = {
  id: Scalars['Float'];
};


export type MutationLeadSignupArgs = {
  input: LeadSignupInput;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['Int'];
  name: Scalars['String'];
  user: User;
  timeEntries: Array<TimeEntry>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  totalDuration: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<User>;
  projects: Array<Project>;
  project?: Maybe<Project>;
  timeEntries: Array<TimeEntry>;
  stats: StatResponse;
};


export type QueryProjectsArgs = {
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryProjectArgs = {
  offset?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
};


export type QueryTimeEntriesArgs = {
  offset?: Maybe<Scalars['Float']>;
};

export type RegisterInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

export type StatResponse = {
  __typename?: 'StatResponse';
  accountsToday: Scalars['Float'];
  accountsThisWeek: Scalars['Float'];
  accountsEver: Scalars['Float'];
  membersToday: Scalars['Float'];
  membersThisWeek: Scalars['Float'];
  membersEver: Scalars['Float'];
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<FieldError>>;
};

export type TimeEntry = {
  __typename?: 'TimeEntry';
  id: Scalars['Int'];
  project?: Maybe<Project>;
  description: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  projectName: Scalars['String'];
};

export type TimeEntryInput = {
  description: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
  projectName: Scalars['String'];
};

export type TimeEntryResponse = {
  __typename?: 'TimeEntryResponse';
  errors?: Maybe<Array<FieldError>>;
  timeEntry?: Maybe<TimeEntry>;
};

export type UpdateTimeEntryInput = {
  description: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
  projectName: Scalars['String'];
  id: Scalars['Float'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  username: Scalars['String'];
  email: Scalars['String'];
  onBoarded: Scalars['Boolean'];
  emailActivated: Scalars['Boolean'];
  role: Scalars['Float'];
  fullMember: Scalars['Boolean'];
  planType: Scalars['String'];
  projects: Array<Project>;
  timeEntries?: Maybe<Array<TimeEntry>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<User>;
};

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )> }
);


export const MeDocument = gql`
    query Me {
  me {
    id
    username
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;