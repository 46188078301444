import React from "react";

interface Props {}

const WistiaTeaser: React.FC<Props> = () => {
  return (
    <div
      style={{
        border:
          process.env.NODE_ENV !== "production"
            ? "1px solid black"
            : "transparent",
      }}
      dangerouslySetInnerHTML={{
        __html: `<script src="https://fast.wistia.com/embed/medias/c6jni7dcgn.jsonp" async></script><script src="https://fast.wistia.com/assets/external/E-v1.js" async></script><div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_c6jni7dcgn seo=false videoFoam=true" style="height:100%;position:relative;width:100%">&nbsp;</div></div></div>`,
      }}
    ></div>
  );
};

export default WistiaTeaser;
