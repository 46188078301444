import React from "react";
import { Box, Flex } from "ticeboxes";
import { AppleIcon, AndroidIcon, H2, P } from "time-tracker-app-lib";

interface Props {}

const DownloadApp: React.FC<Props> = () => {
  return (
    <Box text-center>
      <H2 className="layout-mb-4">Download the app</H2>
      <P className="layout-mb-4" cursive>
        Coming soon!
      </P>
      <Box>
        <Flex
          style={{ maxWidth: 230, margin: "0 auto" }}
          justify-content="between"
        >
          <AndroidIcon comingSoon />
          <AppleIcon comingSoon />
        </Flex>
      </Box>
    </Box>
  );
};

export default DownloadApp;
