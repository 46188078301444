import React from "react";
import { Button } from "time-tracker-app-lib";
import { APP_URL } from "../constants";
import useAuth from "../hooks/useAuth";

interface Props {}

const GetStartedButton: React.FC<Props> = () => {
  const { auth } = useAuth();

  if (auth) {
    return (
      <Button
        secondary
        onClick={() => (window.location.href = `${APP_URL}/dashboard`)}
      >
        Go to dashboard
      </Button>
    );
  }

  return (
    <Button onClick={() => (window.location.href = `${APP_URL}/register`)}>
      Get started
    </Button>
  );
};

export default GetStartedButton;
