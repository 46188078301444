import React, { useEffect, useRef, useState } from "react";
import { Box, Flex } from "ticeboxes";
import { useViewPort } from "time-tracker-app-lib";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";

const Phone: React.FC = () => (
  <img
    src="/iphonex.svg"
    alt="Iphone holder"
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
      transform: "scale(1.16) translateY(4px)",
    }}
  />
);

interface Props {}

const MobilePreview: React.FC<Props> = () => {
  const { breakpoint } = useViewPort();

  const small = { width: 200 };
  const large = { width: 250 };

  const widths = {
    xs: small,
    sm: small,
    md: small,
    lg: large,
    xl: large,
  };

  const imgs = [
    {
      src: "/mobile-dashboard-overview.png",
      alt: "Time tracker app mobile dashboard overview",
    },
    {
      src: "/mobile-project-overview.png",
      alt: "Time tracker app mobile project overview",
    },
  ];

  const [selectedSlide, setSelectedSlide] = useState(0);

  const swiperRef = useRef<any>(null);
  const intervalRef = useRef<number>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (swiperRef.current.activeIndex === imgs.length - 1) {
        for (let i = 0; i < imgs.length - 1; i++) {
          swiperRef.current.slidePrev();
        }
      } else {
        swiperRef.current.slideNext();
      }
    }, 4000);
  }, []);

  useEffect(() => {
    const slideIndex = swiperRef.current.activeIndex;

    if (slideIndex !== selectedSlide) {
      const diff = Math.abs(slideIndex - selectedSlide);
      const isFurther = selectedSlide > slideIndex;

      if (isFurther) {
        for (let i = 0; i < diff; i++) {
          swiperRef.current.slideNext();
        }
      } else {
        for (let i = 0; i < diff; i++) {
          swiperRef.current.slidePrev();
        }
      }
    }
  }, [selectedSlide]);

  const handleTouch = () => {
    clearInterval(intervalRef.current);
  };

  const handleSwipe = (e: any) => {
    setSelectedSlide(e.activeIndex);
  };

  return (
    <Flex justify-content="center" mb="4">
      <Box style={{ ...widths[breakpoint], position: "relative" }}>
        <Phone />
        <Swiper
          autoplay={true}
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={handleSwipe}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          style={{ zIndex: 3 }}
        >
          {imgs.map((img) => (
            <SwiperSlide onTouchStart={handleTouch}>
              <img style={{ width: "100%" }} src={img.src} alt={img.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Flex>
  );
};

export default MobilePreview;
